import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { useMutation } from 'react-query';
import { IPageContext } from '@alterpage/gatsby-plugin-alterpress-page-creator';

import { grid, container, breadcrumbs, header } from './search-results.module.scss';

import ISearchResults from '../models/search-results.model';
import ISearchResult from '../models/search-result.model';
import useTranslations from '../hooks/use-translations';
import { getSearchResults } from '../api-ssr/search';
import useHasMounted from '../hooks/use-has-mounted';

import Heading from '../components/atoms/heading';
import MainLayout from '../layouts/main-layout';
import SearchResultsList from '../components/organisms/search-results-list';
import useQueryParam from '../hooks/use-query-param';
import Loader from '../components/atoms/loader';
import Content from '../components/atoms/content';

interface ISearchResultsProps {
    pageContext: IPageContext;
}

const SearchResults: React.FC<ISearchResultsProps> = ({ pageContext }) => {
    const t = useTranslations('SearchResults');
    const searchQuery = useQueryParam('phrase').values[0];
    const [loadedResults, setLoadedResults] = useState<ISearchResults | ISearchResult[] | null>(
        emptyResults
    );
    const hasMounted = useHasMounted();

    const mutation = useMutation(getSearchResults, {
        onSuccess: (data: ISearchResults) => {
            setLoadedResults(data);
        },
    });

    const { isError, isLoading, mutate } = mutation;

    useEffect(() => {
        mutate({ phrase: searchQuery, mode: pageContext.type });
    }, [searchQuery, pageContext.type, mutate]);

    if (hasMounted && !searchQuery) {
        navigate('/');
        return null;
    }

    if (!hasMounted) {
        return null;
    }

    return (
        <MainLayout
            className={grid}
            breadcrumbsClassName={breadcrumbs}
            additionalSearch={
                pageContext.type === 'location-search-results' ? { type: 'location' } : null
            }
        >
            <div className={container}>
                <Heading numberOfStyle={5} className={header}>
                    {`${t.header} "${searchQuery}"`}
                </Heading>
                {isLoading && <Loader />}
                {!isLoading && !isError && (
                    <SearchResultsList
                        results={
                            Array.isArray(loadedResults)
                                ? { ...emptyResults, locations: loadedResults }
                                : loadedResults
                        }
                    />
                )}
                {isError && isError && <Content numberOfStyle={1}>{t.error}</Content>}
            </div>
        </MainLayout>
    );
};

const emptyResults = {
    news: [],
    pages: [],
    posts: [],
    locations: [],
    directories: [],
    contacts: [],
};

export default SearchResults;
