import fetchApi from './fetch-api';
import ISearchResults, { ISearchCompanyCategories } from '../models/search-results.model';
import { ICompany } from '../models/company.model';

export const getSearchResults = async ({
    phrase = '',
    mode = 'search-results',
}): Promise<ISearchResults> => {
    return await fetchApi('get', getEndpoint(mode), {
        params: { phrase: phrase },
    });
};

export const searchCompanyCategories = async ({
    phrase = '',
}): Promise<ISearchCompanyCategories[]> => {
    return await fetchApi('get', 'companyCategoriesSearch', {
        params: { phrase: phrase },
    });
};

export const searchCompany = async (args: {
    phrase: string;
    tags: string[];
}): Promise<ICompany[]> => {
    return await fetchApi('get', 'companySearch', {
        params: { phrase: args.phrase, tags: args.tags },
    });
};

function getEndpoint(mode: string) {
    if (mode === 'location-search-results') {
        return 'locationSearch';
    }
    return 'search';
}
