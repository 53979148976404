import React, { useState } from 'react';

import {
    wrapper,
    header,
    counter,
    card,
    button,
    overwrite,
} from './search-results-section.module.scss';

import ISearchResult from '../../models/search-result.model';
import useTranslations from '../../hooks/use-translations';

import Heading from '../atoms/heading';
import SearchResultCard from '../atoms/search-result-card';
import Button from '../atoms/button';

interface ISearchResultsSectionProps {
    className?: string;
    results: ISearchResult[];
    title: string;
    type: string;
}

const SearchResultsSection: React.FC<ISearchResultsSectionProps> = ({
    className = '',
    results,
    title,
    type,
}) => {
    const [visibleCounter, setVisibleCounter] = useState(10);
    const t = useTranslations('SearchResultsSection');

    const handleClick = () => {
        setVisibleCounter((state) => state + 10);
    };

    return (
        <div className={`${wrapper} ${className}`}>
            <Heading className={header} numberOfStyle={4}>
                {title} <span className={counter}>({results.length})</span>
            </Heading>
            {results.map((result, index) => {
                if (visibleCounter > index) {
                    return (
                        <SearchResultCard
                            key={result.id}
                            className={card}
                            result={result}
                            type={type}
                        />
                    );
                }
                return null;
            })}
            {results.length > visibleCounter && (
                <Button
                    isOutlined={true}
                    theme={'dark'}
                    className={`${button} ${overwrite}`}
                    onClick={handleClick}
                >
                    {t.button}
                </Button>
            )}
        </div>
    );
};

export default SearchResultsSection;
