import React from 'react';
import { Link } from 'gatsby';

import {
    wrapper,
    wrapperNoImage,
    wrapperContact,
    image,
    imageRatio,
    imageRatioContact,
    header,
    description,
} from './search-result-card.module.scss';

import ISearchResult from '../../models/search-result.model';

import Content from './content';
import RatioImage from './ratio-image';
import Markdown from '../hoc/markdown';

interface ISearchResultCardProps {
    className?: string;
    result: ISearchResult;
    type: string;
}

const SearchResultCard: React.FC<ISearchResultCardProps> = ({ className = '', result, type }) => {
    const hasImage = result.media.length > 0;

    return (
        <Link
            to={result.pathname}
            className={`${wrapper} ${className} ${!hasImage ? wrapperNoImage : ''} ${
                type === 'contacts' ? wrapperContact : ''
            }`}
        >
            {hasImage && (
                <RatioImage
                    className={image}
                    media={result.media}
                    ratioClass={`${imageRatio} ${type === 'contacts' ? imageRatioContact : ''}`}
                />
            )}
            <Content Tag={'div'} className={header} numberOfStyle={1}>
                <Markdown noStyle={true}>{result.title}</Markdown>
            </Content>
            <Content Tag={'div'} className={description} numberOfStyle={2}>
                <Markdown noStyle={true}>{result.content}</Markdown>
            </Content>
        </Link>
    );
};

export default SearchResultCard;
