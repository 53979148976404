import { useEffect, useRef, useState } from 'react';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';

export default function useQueryParam(paramName: string) {
    const { search } = useLocation();
    const urlParams = useRef(new URLSearchParams(search));
    const [values, setValues] = useState(createSet(urlParams.current.getAll(paramName)));

    const setActualValues = () => {
        setValues(createSet(urlParams.current.getAll(paramName)));
    };

    useEffect(() => {
        const nextSearchParams = new URLSearchParams(search);
        const areParamsEqual = nextSearchParams.toString() === urlParams.current.toString();

        if (areParamsEqual) {
            return;
        }

        urlParams.current = new URLSearchParams(search);
        setActualValues();
    }, [search, paramName]);

    const addValue = (value: string) => {
        if (!values.includes(value)) {
            urlParams.current.append(paramName, value);
            setActualValues();
            navigate(`?${urlParams.current.toString()}`);
        }
    };

    const setValue = (value: string) => {
        urlParams.current.set(paramName, value);
        setActualValues();
        navigate(`?${urlParams.current.toString()}`);
    };

    const removeValue = (value?: string) => {
        if (values.length > 1) {
            const newValues = values.filter((item) => item !== value);
            urlParams.current.delete(paramName);
            newValues.forEach((paramValue) => {
                urlParams.current.append(paramName, paramValue);
            });
            setActualValues();
            navigate(`?${urlParams.current.toString()}`);
        } else {
            urlParams.current.delete(paramName);
            setActualValues();
            navigate(`?${urlParams.current.toString()}`);
        }
    };

    return {
        values,
        addValue,
        setValue,
        removeValue,
    };
}

function createSet(array: string[]) {
    return [...new Set(array)];
}
