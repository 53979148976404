import React from 'react';

import { section } from './search-results-list.module.scss';

import ISearchResults from '../../models/search-results.model';
import useTranslations from '../../hooks/use-translations';

import SearchResultsSection from '../molecules/search-results-section';
import Content from '../atoms/content';

interface ISearchResultsListProps {
    results: ISearchResults | null;
}

declare global {
    interface ObjectConstructor {
        entries<Key extends string | symbol, Value>(
            o: Record<Key, Value[]> | ArrayLike<Value[]>
        ): [Key, Value[]][];
    }
}

const SearchResultsList: React.FC<ISearchResultsListProps> = ({ results }) => {
    const t = useTranslations('SearchResultsList');
    const hasAnyResults = results && Object.entries(results).find((value) => value[1].length > 0);
    return (
        <div>
            {hasAnyResults &&
                Object.entries(results).map(([key, results]) => {
                    if (key === 'posts') {
                        if (!resultsHaveGroupId(results)) return null;

                        return filterPostByGroupId(results).map(([groupId, groupResult]) => {
                            return (
                                <SearchResultsSection
                                    key={key}
                                    className={section}
                                    results={groupResult}
                                    //@ts-ignore
                                    title={t.posts[groupId] ?? t.posts[0]}
                                    type={key}
                                />
                            );
                        });
                    }
                    if (results.length > 0) {
                        return (
                            <SearchResultsSection
                                key={key}
                                className={section}
                                results={results}
                                title={t[key]}
                                type={key}
                            />
                        );
                    }

                    return null;
                })}
            {!hasAnyResults && <Content numberOfStyle={1}>{t.empty}</Content>}
        </div>
    );
};

function filterPostByGroupId(results: ISearchResults['posts']) {
    const map: Record<number, ISearchResults['posts']> = {};

    for (const result of results) {
        if (!map[result.groupId]) {
            map[result.groupId] = [];
        }

        map[result.groupId] = [...map[result.groupId], result];
    }

    return Object.entries(map);
}

function resultsHaveGroupId(results: unknown[]): results is ISearchResults['posts'] {
    return typeof results[0] === 'object' && results[0] !== null && 'groupId' in results[0];
}

export default SearchResultsList;
